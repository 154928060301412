<template>
  <AtroContent class="w-full space-x-2 py-4 sm:py-8" justify="center">
    <NuxtLink to="https://atro.com/privacy" target="_blank"
      >Privacy Policy</NuxtLink
    >
    <AtroSpan>-</AtroSpan>
    <NuxtLink to="https://atro.com/terms" target="_blank"
      >Terms & Conditions</NuxtLink
    >
  </AtroContent>
</template>
