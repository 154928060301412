<template>
  <AtroContent
    class="min-h-full w-full"
    items="center"
    wrap="nowrap"
    :direction="$isMobile.value ? 'col' : 'row'"
  >
    <!-- MOBILE TRIAL BANNER -->
    <AtroContent
      v-if="showTrialBanner"
      class="banner-gradient relative w-full p-3 text-white"
      direction="col"
      items="center"
      justify="center"
      @click="openModal('subscriptionProInfo')"
    >
      <AtroIcon class="absolute right-3 top-3 h-4 w-4" name="circle-info" />

      <AtroContent class="space-x-2" items="center">
        <AtroIconWrap circle name="rocket-launch" size="xs" :bordered="false" />
        <AtroHeading semibold size="lg">Atro Pro</AtroHeading>
      </AtroContent>
      <AtroParagraph size="sm"
        >Free for 30 days when you sign up now</AtroParagraph
      >
    </AtroContent>

    <AtroContent
      direction="col"
      items="center"
      wrap="nowrap"
      :class="[
        'relative h-full w-full',
        { 'sm:w-1/2': layoutConfig.showFreeTrial },
      ]"
    >
      <LogoutButton
        v-if="status === 'authenticated'"
        class="!absolute right-6 top-6"
      />
      <AtroContent v-if="isWebContext" class="mt-20 py-6 sm:mt-0 sm:py-14">
        <AtroIcon class="h-12 w-48 text-primary" name="atro-logo-horizontal" />
      </AtroContent>
      <AtroContent
        shrink
        items="center"
        justify="center"
        direction="col"
        :class="['w-full flex-1', { 'px-2': isWebContext }]"
      >
        <slot />
      </AtroContent>
      <PublicFooter v-if="isWebContext" />
    </AtroContent>
    <AtroContent
      v-if="showTrialUpsell"
      class="banner-gradient h-full w-1/2 p-6"
      items="center"
      justify="center"
    >
      <AtroCard class="shadow-card-white" size="md" type="auth">
        <AtroIconWrap
          circle
          class="banner-gradient h-16 w-16 !border-0 text-white"
          name="rocket-launch"
        />
        <AtroHeading semibold class="mt-2 text-atro-slate-purple" size="xl"
          >Try Atro Pro</AtroHeading
        >
        <AtroParagraph class="mt-2 max-w-[210px] text-center text-atro-gray"
          >Free for 30 days when you sign up now. No credit card
          required.</AtroParagraph
        >

        <AtroDivider size="xl" />

        <AtroContent class="w-full" direction="col">
          <AtroSpan semibold class="text-atro-gray">What’s included</AtroSpan>
          <SubscriptionFeatureList expanded plan-type="pro" />
        </AtroContent>
      </AtroCard>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
const isMobile = useIsMobile()
const router = useRouter()
const { status } = useAuth()
const { isWebContext } = useClientContext()
const { layoutConfig } = useLayoutConfig()
const { openModal } = useModal()

const showTrialBanner = computed(
  () =>
    isWebContext.value &&
    isMobile.value &&
    ['login', 'signup'].includes(router.currentRoute.value.name as string),
)
const showTrialUpsell = computed(
  () =>
    isWebContext.value && layoutConfig.value.showFreeTrial && !isMobile.value,
)
</script>
